var $ = require('../internals/export');
var trunc = require('../internals/math-trunc');

// `Math.trunc` method
// https://tc39.es/ecma262/#sec-math.trunc
$({
  target: 'Math',
  stat: true
}, {
  trunc: trunc
});