'use strict';

var $ = require('../internals/export');
var uncurryThis = require('../internals/function-uncurry-this');
var fails = require('../internals/fails');
var FORCED = fails(function () {
  return new Date(16e11).getYear() !== 120;
});
var getFullYear = uncurryThis(Date.prototype.getFullYear);

// `Date.prototype.getYear` method
// https://tc39.es/ecma262/#sec-date.prototype.getyear
$({
  target: 'Date',
  proto: true,
  forced: FORCED
}, {
  getYear: function getYear() {
    return getFullYear(this) - 1900;
  }
});