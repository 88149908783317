var $ = require('../internals/export');
var log10 = require('../internals/math-log10');

// `Math.log10` method
// https://tc39.es/ecma262/#sec-math.log10
$({
  target: 'Math',
  stat: true
}, {
  log10: log10
});