var $ = require('../internals/export');

// `Number.MAX_SAFE_INTEGER` constant
// https://tc39.es/ecma262/#sec-number.max_safe_integer
$({
  target: 'Number',
  stat: true,
  nonConfigurable: true,
  nonWritable: true
}, {
  MAX_SAFE_INTEGER: 0x1FFFFFFFFFFFFF
});