var $ = require('../internals/export');
var trimEnd = require('../internals/string-trim-end');

// `String.prototype.trimRight` method
// https://tc39.es/ecma262/#sec-string.prototype.trimend
// eslint-disable-next-line es/no-string-prototype-trimleft-trimright -- safe
$({
  target: 'String',
  proto: true,
  name: 'trimEnd',
  forced: ''.trimRight !== trimEnd
}, {
  trimRight: trimEnd
});